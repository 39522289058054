import { Api } from "@/apis";
export const allProps = {
  isShowBtn: {
    addBtn: true,
  },
  dialog: {
    isShow: false,
    title: "编辑",
  },
  data: { dizhi: [], limit: [], phone: "1231" },
  items: [
    { label: "门店名", prop: "shopName", type: ["table"] },
    { label: "设备号", prop: "s_id", type: ["table", "add"] },
    {
      label: "门店",
      prop: "m_id",
      type: ["add"],
      Ftype: "select",
      list: [],
    },
    { label: "MAC地址", prop: "MAC", type: ["table", "add", "edit"] },
    {
      label: "运营状态",
      prop: "state",
      type: ["table", "add", "edit"],
      Ttype: "slot",
      Ftype: "radio",
      list: [],
    },
    {
      label: "操作",
      prop: "operation",
      Ttype: "slot",
      type: ["table"],
      width: "150px",
    },
  ],
  api: Api.equipment,
};
