<template>
  <div>
    <AllPage ref="AllPage" :allProps="allProps" @submitSuccess="submitSuccess">
      <template v-slot:state="scope">
        <span v-for="item in stateList" :key="item.value">{{
          scope.data.state.includes(item.value) ? item.label : ""
        }}</span>
      </template>
      <template v-slot:operation="scope">
        <el-button
          size="small"
          @click.native.prevent="dialogForm('编辑', scope.data)"
        >
          编辑
        </el-button>
        <el-button
          size="small"
          @click.native.prevent="deleteRow(scope.data.id)"
        >
          删除
        </el-button>
      </template>
    </AllPage>
  </div>
</template>
<script>
import { Api } from "@/apis";
import { allProps } from "./config";
import { messageBox } from "@/utils/messageBox";
export default {
  components: {
    AllPage: () => import("@/components/AllPage/AllPage.vue"),
  },
  provide() {
    return {
      changeRuleForm: this.changeRuleForm,
    };
  },
  data() {
    return {
      tableData: {},
      allProps,
      Api,
      stateList: [
        { value: "Working", label: "正常使用" },
        { value: "Repairing", label: "维修中" },
        { value: "Disabled", label: "禁用" },
      ],
    };
  },

  created() {
    this.allProps.items.forEach((item) => {
      if (item.prop === "shopName") {
        item.options = this.$store.state.space;
      } else if (item.prop === "m_id") {
        let { token, name } = JSON.parse(localStorage.userInfo);
        let obj = {
          page: 1,
          pageSize: 100,
          name,
          token,
        };
        this.$axios.post(Api.shop.get, obj).then((res) => {
          res.data.data.forEach((v) => {
            item.list.push({ name: v.ename, id: v.id });
          });
        });
      } else if (item.prop === "state") {
        item.list = this.stateList;
      }
    });
  },
  methods: {
    changeRuleForm(data) {
      let { token, name, MAC, state, id, s_id, m_id } = data;
      return { token, name, MAC, state, id, s_id, m_id };
    },
    changeTableList(data) {
      data.forEach((item) => {
        item.shopName = item.shopinfo[0]?.name;
        this.tableData = data;
      });
    },
    submitSuccess(val) {
      val && this.$refs.AllPage.getTableList();
    },
    dialogForm(title, row = {}) {
      allProps.dialog = {
        isShow: true,
        title,
      };
      allProps.data = JSON.parse(JSON.stringify(row));
    },
    async deleteRow(id) {
      let result = await messageBox(Api.equipment.dele, { id });
      if (result) this.$refs.AllPage.getTableList();
    },
  },
};
</script>
<style lang="less" scoped>
.el-tag {
  margin-right: 5px;
}
</style>
